import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger.js';
gsap.registerPlugin( ScrollTrigger );
( function( app ) {
  const COMPONENT = {
    init: function() {
      const _this = this;
    },
    finalize: function() {
    },
    animate: function() {
      // global animations in animate will load in after running common.js init function, all component javascript, and before common.js finalize function.
      // This should improve animations loading in and fix odd start times due to load order
      /*
       * Fade on scroll
       */
      $( '.js-fade:not(.js-ignore), .js-fade-group > *:not(.js-ignore)' ).each( function() {
        ScrollTrigger.create( {
          trigger: this,
          start: 'top 90%',
          scrub: 0.15,
          onRefresh: ( self ) => {
            if ( self.progress > 0 ) {
              $( this ).addClass( 'js-animated' );
            }
          },
          onEnter: ( {progress, direction, isActive} ) => $( this ).addClass( 'js-animated' ),
        } );
      } );

      /*
       * Reveal on scroll
       */
      $( '.js-reveal:not(.js-ignore)' ).each( function() {
        gsap.to( this, {
          scrollTrigger: {
            trigger: this,
            start: 'top 90%',
            scrub: false,
          },
          clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%)', duration: 1.5, ease: 'power4.inOut',
        } );
      } );
    },
  };
  app.registerComponent( 'animations', COMPONENT );
} )( app );
